.react-phone-number-input__icon {
  border: none;
  width: 2em;
  height: 1.2em;
  padding-bottom: 10px;
}

.react-phone-number-input__country-select-arrow {
  margin-top: 0.1em;
  width: 7px;
  height: 5px;
}

.react-phone-number-input__input {
  border: none;
}

::-webkit-input-placeholder {
  color: #adb5bd;
}

.form-control-alternative {
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
}