@font-face {
  font-family: "HomepageBaukasten-Book";
  src: local("HomepageBaukasten-Book"),
    url(../../../assets/fonts/HomepageBaukasten-Book.ttf) format("truetype");
}
.optionSelectorBox {
  font-family: "HomepageBaukasten-Book";
  background: #ffffff;
  border: 1px solid #e5eced;
  border-radius: 5px;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px 0px;

  .option {
    padding: 7px 7px;
  }
  .selected {
    background: #4a5bea;
    color: white;
    box-shadow: 0px 5px 5px rgba(117, 155, 250, 0.369877);
    border-radius: 5px;
  }
  button {
    background: #4a5bea00;
    border: none;
    width: 100%;
    font-size: 13px;
    color: #273d52;
    font-weight: 500;
  }
  .white {
    color: #ffffff;
  }
}
