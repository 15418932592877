$brand-primary: #4a5bea;
$grey-light: #ededed;
%remain-steps {
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  &:after {
    background-color: $grey-light;
  }
}
.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;

    div {
      padding-top: 5px;
      font-weight: 300;
    }

    @media (max-width: 576px) {
      div {
        font-size: 0.7rem;
        padding-top: 2px;
      }
    }

    &:before {
      content: "\f00c";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      margin: 0 auto 4px;
      background-color: $brand-primary;
      color: white;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border-width: 2px;
      border-style: solid;
      border-color: $brand-primary;
      border-radius: 50%;
    }
    &:after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: $brand-primary;
      position: absolute;
      top: 16px;
      left: 50%;
      z-index: -1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

    &.active {
      @extend %remain-steps;
      &:before {
        background-color: white;
        border-color: $brand-primary;
        color: $brand-primary;
      }

      ~ li {
        color: #808080;
        @extend %remain-steps;
        &:before {
          background-color: $grey-light;
          border-color: $grey-light;
        }
      }
    }
  }
}
