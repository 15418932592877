.bubble-button {
  padding: 0.5rem 0.5rem !important;
  display: block;
  max-height: fit-content;
  max-width: fit-content;
  border: 0.5px solid #cccccc;
  border-width: thin;
  border-radius: 50px;
  font-size: smaller;
  background: none;
  color: #808080;
  &:hover {
    background-color: #4a5bea;
    color: white;
  }
}
