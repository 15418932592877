$brand-primary: #4a5bea;
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;

  i {
    font-size: 23px;
    margin-right: 0.5rem;
    flex-direction: column;
    align-self: center;
  }

  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    width: 10px;
    height: 90%;
    border-left: 2px dashed #ccc;
  }

  &-item {
    position: relative;
    counter-increment: list;
    min-height: 40px;
    background: #ffffff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 13px;
    margin-bottom: 10px;
    display: flex;

    &::before {
      display: inline-block;
      content: "";
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
      top: 25px;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 15px;
      left: -39px;
      width: 20px;
      height: 20px;
      border: 2px solid #ccc;
      border-radius: 50%;
      background-color: #fff;
    }

    &.is-done {
      &::before {
        border-left: 2px solid;
        color: $brand-primary;
      }
      &::after {
        content: "\f00c";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 0.6rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        margin: 0 auto 4px;
        background-color: $brand-primary;
        color: white;
        width: 21px;
        height: 21px;
        line-height: 17px;
        text-align: center;
        font-weight: bold;
        border-width: 2px;
        border-style: solid;
        border-color: $brand-primary;
        border-radius: 50%;
      }
    }

    &.current {
      &::after {
        content: counter(list);
        padding-top: 0px;
        width: 25px;
        height: 25px;
        top: 10px;
        left: -41px;
        font-size: 14px;
        text-align: center;
        color: #5533ff;
        border: 2px solid #5533ff;
        background-color: white;
      }

      &::before {
        border: none;
      }
    }
  }
}
