.book-cta {
  width: 80%;
  padding: 1rem 0.5rem;
  z-index: 15;
  // margin-left: 28px;
  // margin-left: -120px;
  position: relative;
  left: 10%;
  bottom: 20px;
}
.next-cta {
  width: 28%;
  padding: 1rem 0.5rem;
  z-index: 15;
  // margin-left: 28px;
  // margin-left: -120px;
  position: relative;
  right: 10%;
  bottom: 20px;
  margin-top: 120px;
  float: right;
}
.prev-cta {
  width: 28%;
  padding: 1rem 0.5rem;
  //padding: 1rem;
  z-index: 15;
  // margin-left: 28px;
  // margin-left: -120px;
  position: relative;
  left: 10%;
  bottom: 20px;
  margin-top: 120px;
  float: left;
}

.share-btn {
  position: absolute;
  top: 125px;
  bottom: -5px;
  right: 8%;
  height: 60px;
  width: 60px;
  z-index: 2;
}

.cardContainer {
  overflow: hidden;
  .price {
    line-height: 1em;
  }
}

@media only screen and (min-width: 600px) {
  .cardContainer {
    border: 1px solid #c4c4c4;
    padding: 20px 30px;
    border-radius: 10px;
  }
  .book-cta {
    position: relative;
    width: unset;
    padding: 1rem 3rem;
    // margin: 0px auto;
    // left: unset;
  }
  .next-cta {
    position: relative;
    width: unset;
    padding: 1rem 3rem;
    // margin: 0px auto;
    // left: unset;
  }
  .prev-cta {
    position: relative;
    width: unset;
    // padding-right: 5rem;
    padding: 1rem 3rem;
    // margin: 0px auto;
    // left: unset;
  }
  .share-btn {
    position: absolute;
    right: 10px;
    top: 15%;
  }
}

@media only screen and (min-width: 1200px) {
  .share-btn {
    right: 10%;
  }
}
