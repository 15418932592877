bubble-button {
  padding: 0.5rem 0.5rem !important;
  display: block;
  max-height: fit-content;
  max-width: fit-content;
  border: 0.5px solid #cccccc;
  border-width: thin;
  border-radius: 50px;
  font-size: smaller;
  background: none;
  color: #808080;
  &:hover {
    background-color: #4a5bea;
    color: white;
  }
}
.bubbleRadioBox {
  font-family: "HomepageBaukasten-Book";
  background: #ffffff;
  //border: 1px solid #e5eced;
  //border-radius: 5px;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 0px;

  .option {
    padding: 0px 0px;
  }
  .selected {
    background: #4a5bea;
    color: white;
    // border-radius: 50px;
    border: 0.5px solid #cccccc;
    &:hover {
      background: #4a5bea;
      color: white;
    }
  }
  button {
    flex: 1 1 1 1;
    padding: 0.5rem 0.5rem !important;
    display: block;
    max-height: fit-content;
    max-width: 110px;
    min-width: 110px;
    border: 0.5px solid #cccccc;
    border-width: thin;
    border-radius: 50px;
    font-size: smaller;
    background: none;
    color: #808080;
    margin-top: 10px;
    &:hover {
      background-color: #808080;
      color: white;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: 0;
    }
  }
  .white {
    color: #ffffff;
  }
}
