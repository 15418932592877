.layout {
  min-height: 100vh;
  height: 100%;
  display: flex;
  height: auto;

  &__main-heading {
    $self: &;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;

    &--with-button {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__text-with-heading {
    margin-bottom: 2rem;
    margin: auto;
    width: 350px;
  }

  &--with-image {
    $self: &;

    &__left-section {
      width: 25%;
      min-height: 100vh;
      background: linear-gradient(
        125deg,
        #ecfcff 0%,
        #ecfcff 40%,
        #b2fcff calc(40% + 1px),
        #b2fcff 60%,
        #5edfff calc(60% + 1px),
        #5edfff 72%,
        #3e64ff calc(72% + 1px),
        #3e64ff 100%
      );

      // @media (max-width: 768px) {
      //   //@media (min-width: 992px) {
      //   visibility: hidden;
      // }

      // @media (max-width: 1200px) {
      //   visibility: hidden;
      // }
    }

    &__right-section {
      width: 75%;
      height: 100%;
      display: flex;
      margin: 0px auto;

      // //  @include media("<lg-tablet") {
      // @media (max-width: 768px) {
      //   width: 100%;
      // }

      // //  @include media(">=lg-tablet", "<xl-tablet") {
      // @media (max-width: 1200px) {
      //   width: 100%;
      // }
    }
  }
  &--plain {
    width: 100vw;
    $self: &;
    &__main-section {
      width: 100%;
      height: 100%;
      display: flex;
      margin: auto;
    }
  }
}
