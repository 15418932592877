.tenant {
  .hero {
    position: relative;
    // background: url("./../../../assets/img/illustrations/book-visit.svg");
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
      background: url("./../../../assets/img/illustrations/hero.webp"),
        url("./../../../assets/img/illustrations/hero.jpg");
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }

  .hero-text {
    z-index: 2;
    padding-top: 5vh;
  }

  .hero-separator {
    z-index: 2;
  }

  .btn-wrapper {
    max-width: 360px;
  }

  .quote {
    color: white;
    padding-left: 15px;
    border-left: 1.5px solid;
  }
}

@media (min-width: 375px) {
  .tenant {
    .carousel-item {
      min-height: unset;
    }
    .btn-wrapper {
      max-width: 240px;
    }
  }
}

@media (min-width: 768px) {
  .tenant {
    .hero-text {
      padding-top: 10vh !important;
    }
    .feature-card {
      height: 361px;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    @media only screen and (max-width: 992px) {
      .feature-card {
        height: 480px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
