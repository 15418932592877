.logo {
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    vertical-align: top;
    color: #ffffff;
    padding-top: 12px;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: -10px;
  }
  img {
    padding: 5 px 0px 11px 0px;
    width: 50px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .desktoponly {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .desktoponly {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .desktoponly {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
