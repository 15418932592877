.price-tag {
  margin-top: -36px;
  border-radius: 0px 5px 5px 0px;
}

@media (min-width: 994px) {
  .price-tag {
    margin-left: -200px;
    margin-top: 164px;
    border-radius: 5px 0px 0px 5px;
  }
}
