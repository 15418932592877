.footer-wrapper {
  background: #f7f9ff;
  padding-bottom: 0px;
  margin-top: 50px;
}
.footer-landing {
  justify-content: center;
  display: grid;
  justify-content: space-evenly;
  padding: 0px 20px;
  grid-column-gap: 20px;
  grid-template-areas: "company resources" "social social" "address address" "copyright copyright";

  ul {
    padding-left: 0px;
    list-style-type: none;
  }
  .social-item {
    li {
      display: inline-block;
      padding-right: 2em;
    }
  }
  a {
    font-weight: 200;
    line-height: 2em;
    color: grey;
  }
  .social-icon {
    font-size: 1.2em;
    width: 1.5em;
  }

  .company-links {
    grid-area: company;
  }

  .resources-links {
    grid-area: resources;
  }

  .social-links {
    grid-area: social;
  }

  .igen-address {
    grid-area: address;
    font-weight: 100;
    color: grey;
    line-height: 2em;
  }
}
.copyright {
  padding: 40px 0px 20px 0px;
  text-align: center;
  font-weight: 100;
  grid-area: copyright;
}

@media (min-width: 768px) {
  .footer-landing {
    padding: 60px 20px 20px 20px;
    .copyright {
      padding: 70px 0px 20px 0px;
    }
    .footer-landing {
      line-height: 2.5em;
      padding: 50px 70px 20px 70px;
      display: grid;
      grid-template-areas: "address address company resources" "address adress social social" "copyright copyright copyright copyright copyright";
    }
    .social-item {
      li {
        display: inline-block;
        padding-right: 2em;
      }
    }
  }
}
@media (min-width: 800px) {
  .footer-landing {
    grid-template-areas: "address address company resources social" "copyright copyright copyright copyright copyright";

    .social-item {
      li {
        display: block;
        padding-right: unset;
      }
    }
  }
}
