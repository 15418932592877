.landlord {
  .btn-wrapper {
    max-width: 360px;
  }
  .hero {
    position: relative;
    // background: url("./../../../assets/img/illustrations/book-visit.svg");
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
      background: url("./../../../assets/img/illustrations/hero.webp"),
        url("./../../../assets/img/illustrations/hero.jpg");
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  .hero-text {
    padding-top: 5vh !important;
  }

  .section-shaped {
    .shape {
      height: unset;
    }
  }

  .hero-text {
    z-index: 2;
  }

  .hero-separator {
    z-index: 2;
  }

  .quote {
    padding-left: 15px;
    border-left: 1.5px solid #4a5bea;
    line-height: 2;
  }

  .reading-comfort {
    line-height: 2;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .btn-wrapper {
      max-width: 240px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .hero-text {
      padding-top: 10vh !important;
      padding-bottom: 10vh !important;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .hero {
      // height: 80vh;
      &:before {
        background-position: 50% 40%;
      }
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
