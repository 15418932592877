.about-us {
  .hero-old {
    position: relative;
    background: url("./about-us.jpg");
    background-position-x: 38%;
    background-position-y: center;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    &::before {
      content: ""; // ::before and ::after both require content
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(87deg, #4a5bea 0, #7c4aea 100%);
      opacity: 0.5;
    }
  }
  .hero {
    position: relative;

    background-position-x: 38%;
    background-position-y: center;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
  .profilePic {
    position: relative;
    background: url("./profilepic.jpg");
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 400px;
  }
  h3.quote {
    z-index: 2;
    display: flex;
    padding-left: 5px;

    &::before,
    &::after {
      font-size: 3rem;
    }
    &::after {
      content: close-quote;
    }
    &::before {
      content: open-quote;
    }
  }
  .special-text {
    padding-left: 15px;
    border-left: 1.5px solid var(--primary);
    font-weight: 100;
    margin: 50px 30px;
  }
  p {
    line-height: 2;
  }

  .sub-title {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  li {
    line-height: 2.5;
    list-style-type: circle;
    font-weight: 300;
    span {
      display: flex;
      padding-left: 5px;
    }
  }

  #youtube-video {
    margin-top: 50px;
    height: 300px;
  }
}

@media (min-width: 992px) {
  .about-us {
    .hero {
      background-position: center;
    }
    h3.quote {
      display: block;
    }
    #youtube-video {
      height: 500px;
    }
  }
}
