.contact-card {
  background: #5c6be5;
  border: none;
  line-height: 2;

  .form-cta {
    background: #ffffff;
  }

  .section-contact-us {
    .form-control::placeholder {
      font-weight: 100 !important;
    }
  }
}

@media (min-width: 375px) {
  .contact-card {
    .hero-holder {
      height: 80vh;
    }
  }
}

@media (min-width: 768px) {
  .contact-card {
    background: linear-gradient(90deg, #5c6be5 50%, #ffffff 50%);
    input {
      background: #e0e7ff77;
      border: 1px solid #e0e7ff;
      font-weight: 100;
    }
    .form-cta {
      background: #4a5bea;
      color: white;
    }
    textarea {
      background: #e0e7ff77;
      border: 1px solid #e0e7ff;
      font-weight: 100;
    }
  }
}
